import React from "react";
import data from "../../DATA.json";

const ProjectsPage = () => {
  const headerTitles = ["Year", "Project", "Images", "Tech Stacks", "Link"];

  return (
    <>
      <div className="mx-auto min-h-screen max-w-screen-xxl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0">
        <div className="lg:py-24">
          <a
            href="/"
            className="group mb-2 inline-flex items-center font-semibold leading-tight text-teal-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="mr-1 h-4 w-4 rotate-180 transition-transform group-hover:-translate-x-2"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                clipRule="evenodd"
              ></path>
            </svg>
            Ana Teston
          </a>
          <h1 className="text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl">
            ALL PROJECTS
          </h1>
          <style>
            {`
            .img-wrapper {
              width: 200px;
              height: 80px;
              object-fit: cover; /* Maintain aspect ratio and fill container */
            }
            /* Custom CSS styles for hiding headers on small devices */
            @media (max-width: 500px) {
              th:nth-child(3), /* Images */
              th:nth-child(4) /* Tech Stacks */ {
                display: none;
              }
              @media (max-width: 500px) {
                /* Images column */
                td:nth-child(3) {
                  display: none;
                }
                /* Tech Stacks column */
                td:nth-child(4) {
                  display: none;
                }
            }
          `}
          </style>
          <table
            id="content"
            className="mt-12 px-8 sm:w-full border-collapse text-left"
          >
            <thead className="sticky top-0 z-10 border-b border-slate-300/10 bg-zinc-900/75 px-8 py-5 backdrop-blur">
              <tr>
                {headerTitles.map((title, index) => (
                  <th
                    key={index}
                    className={`py-4 px-8 text-sm font-semibold text-slate-200 ${
                      title === "Tech Stacks" ? "hidden sm:hidden lg:block" : ""
                    }`}
                  >
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.projects.map((project) => (
                <tr
                  key={project.id}
                  className="border-b border-slate-300/10 last:border-none"
                >
                  <td className="py-4 px-8 text-sm font-normal text-slate-200">
                    <div className="translate-y-px">{project.date}</div>
                  </td>
                  <td className="py-4 px-8 text-sm font-medium text-slate-200">
                    {project.title}
                  </td>
                  <td
                    className="py-4 px-8 text-sm font-normal text-slate-200"
                    aria-label={project.image}
                  >
                    <img
                      alt={project.title}
                      loading="lazy"
                      width="200"
                      height="48"
                      decoding="async"
                      data-nimg="1"
                      className="img-wrapper z-10 rounded border-2 border-slate-200/10 transition group-hover:border-slate-200/30 sm:order-1 sm:col-span-2 sm:translate-y-1"
                      style={{ color: "transparent" }} // Fix the style attribute here
                      aria-label={project.image}
                      src={project.image}
                    />
                  </td>
                  <td
                    className={`py-4 pr-8 text-sm font-normal text-slate-200 ${"hidden sm:hidden lg:block"}`}
                  >
                    <div className="translate-y-px">
                      <ul
                        className="mt-2 flex flex-wrap"
                        aria-label="Technologies used"
                      >
                        {project.techStacks.map((tech) => (
                          <li key={tech} className="mr-1.5 mt-2">
                            <div className="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300">
                              {tech}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </td>
                  <td className="py-4 align-top sm:table-cell">
                    <a
                      href={project.link}
                      className="text-teal-300 hover:text-teal-400"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Project{" "}
                      <span className="inline-block border-b border-transparent pb-px transition group-hover:border-teal-300 motion-reduce:transition-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </a>

                    <div className="py-4">
                      <a
                        href={project.gitH}
                        className="text-teal-300 hover:text-teal-400"
                        target="_blank"
                        rel="noreferrer"
                      >
                        GitHub{" "}
                        <span className="inline-block border-b border-transparent pb-px transition group-hover:border-teal-300 motion-reduce:transition-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProjectsPage;
