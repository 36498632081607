import React from "react";

const Footer = () => {
  return (
    <div className="bg-zinc-950 py-16 ">
      <div className="max-w-md mx-auto pl-6 pr-2 text-sm text-slate-500">
        <p>
          Designed by{" "}
          <a
            href="https://brittanychiang.com/"
            className="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-2s00 cursor-pointer"
          >
            Brittany Chiang
          </a>{" "}
          whom I thank immensely for letting me use your design. Coded by me in{" "}
          <span className="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-300">
            Visual Studio Code
          </span>
          . Built with{" "}
          <span className="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-300">
            React.js
          </span>{" "}
          and{" "}
          <span className="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-300">
            Tailwind CSS
          </span>
          , deployed with{" "}
          <span className="font-medium text-slate-400 hover:text-teal-300 focus-visible:text-teal-300">
            Netlify
          </span>
          .
        </p>
        <p> © 2023 Paula Teston - Frontend Developer Based in Malta. All rights reserved. </p>
      </div>
    </div>
  );
};

export default Footer;
