import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../../components/navbar/navbar.component";
import SocialMediaLinks from "../../components/sociaMediaLinks/socialMediaLinks";
import Main from "../../components/main/main"

const Layout = () => {
  return (
    <>
      <div className="lg:flex lg:justify-between lg: gap-4">
        <div
          className={
            "lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24"
          }
        >
          <div>
            <h1
              className={
                "text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl"
              }
            >
              Paula Teston
            </h1>
            <h2
              className={
                "mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xxl"
              }
            >
              Full Stack Developer | Project Manager
            </h2>
            <p className="mt-4 max-w-xxs leading-normal text-slate-300">
              I’ve mastered the art of developing workflow processes, maintaining quality standards, and unleashing innovation, whether through leading teams as a project manager or crafting efficient solutions as a full stack developer.
            </p>
            <Navigation />
          </div>
          <SocialMediaLinks />
        </div>
        <Main />
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
