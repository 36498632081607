import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import Layout from './routes/home/layout.jsx';
import Spotlight from './components/spotlight/spotlight.jsx';
import ProjectsPage from './components/projects/allProjectsPage.jsx';

const App = () => {
  return (
    <Router>
      <Spotlight />
      <div className="bg-zinc-950 leading-relaxed text-zinc-400 antialiased selection:bg-zinc-300 selection:text-zinc-900 mx-auto min-h-screen max-w-screen-xxl px-6 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0"> {/* Set the background color for the body */}
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/projects" element={<ProjectsPage />} />
          {/* Add other routes as needed */}
        </Routes>
      </div>
    </Router>
  );
};
export default App;
