import React from "react";
import About from "../about/about";
import Experience from "../experience/experience";
import Projects from "../projects/projects";
import Footer from "../footer/footer"
import Management from "../../management/management";

const Main = () => {
  return (
    <>
    <main id="content" className="pt-24 lg:w-1/2 lg:py-24">
      <About />
      <Experience />
      <Management />
      <Projects />
      <Footer />
    </main>



    </>
  );
};
export default Main;
