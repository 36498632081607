import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="About-me"
    >
      <div className="sticky top-0 z-20 mx-0 mb-4 md:w-screen sm:w-full bg-zinc-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200">
          About
        </h2>
      </div>
      <div>
        <p className="mb-4">
          Throughout my career, I have excelled in managing diverse projects,
          leading teams, and handling complex contracts, including at the
          prestigious {" "} <a
            href="http://www.theatromunicipal.org.br/pt-br/"
            className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
            target="_blank"
            rel="noreferrer"
          >
            Theatro Municipal de São Paulo - Brazil.</a> As a project manager, I have demonstrated precision,
          efficiency, and fostered a positive work environment, achieving
          successful outcomes and meeting deadlines ahead of schedule.
        </p>
        <p>
          In 2020, I discovered my passion for programming through UX research
          and subsequently delved into front-end development with projects like
          Bomjour bakery, showcasing my proficiency in
          {" "} <a
            href="https://golden-kheer-6742a2.netlify.app/"
            className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
            target="_blank"
            rel="noreferrer"
          >React, Redux and Typescript</a>. Later, I honed my skills in back-end development with an intensive
          bootcamp focused on {" "} <a
            href="http://www.animetrek.me/"
            className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
            target="_blank"
            rel="noreferrer"
          >Ruby and Ruby on Rails</a>.
        </p>
        <br />
        <p>
        Currently, {" "} <a
            href="https://darttek.com/"
            className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
            target="_blank"
            rel="noreferrer"
        >Darttek</a>, I am collaborating with the team to migrate the platform from Angular to a modern architecture using Vue 3 and TypeScript. This involves developing modular libraries and enhancing performance and maintainability through strategic refactoring and optimized state management. My experience at {" "} <a
        href="https://www.aohostels.com/en/"
        className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
        target="_blank"
        rel="noreferrer"
        >a&o hostels</a>, involved redesigning web pages and developing PDF invoicing, while at {" "} <a
        href="https://drsmile.de/drsmilexplusdental/"
        className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
        target="_blank"
        rel="noreferrer"
        >PlusDental</a>, I specialized in 3D product quality control.
          </p>
        <br />
        <p>
        With my broad expertise in both project management and software development, I am poised to tackle new challenges, whether by leading teams to operational excellence or by creating innovative {" "} <a
            href="https://www.linkedin.com/in/anateston/"
            className="font-medium text-slate-200 hover:text-teal-300 focus-visible:text-teal-300"
            target="_blank"
            rel="noreferrer"
          >solutions</a> in development.
        </p>
      </div>
    </section>
  );
};
export default About;
